import React, { useEffect } from 'react';
import { Dialog, DialogActions, Button, Typography, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import toast from 'react-hot-toast';
import { addDays } from 'date-fns';
import { useState } from 'react';
import { enUS } from 'date-fns/locale';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
const RecurringRangeModal = ({ open, onClose, handleData }) => {
  const [filterId, setFilterId] = useState<number>(0);
  const handleCancel = () => {
    onClose(filterId);
  };
  const filteredData = handleData?.filter(item => !item.parent);
  const startDateValues = filteredData?.map(item => new Date(item.startDate));
  const activeDates = startDateValues?.map(dateString => new Date(dateString));
  const isDateActive = date => {
    return activeDates.some(
      activeDate =>
        date.getFullYear() === activeDate.getFullYear() &&
        date.getMonth() === activeDate.getMonth() &&
        date.getDate() === activeDate.getDate()
    );
  };
  const handleDateClick = date => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');
    const outputDateString = `${year}-${month}-${day}`;
    const data = handleData.filter(item => !item.parent);
    const filteredData = data.filter(item => {
      const startDateFormatted = new Date(item.startDate).toISOString();
      return outputDateString === startDateFormatted?.split('T')[0];
    });

    setFilterId(filteredData[0]?.id);
    handleCancel();
  };
  useEffect(() => {
    if (filterId != 0) {
      handleCancel();
    }
  }, [filterId]);

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="xs">
      <style>
        {`.activeDate {
      color: white !important;
       border-radius:48% !important;
       padding :10px;
       background:#674BFF !important;
    }`}

        {`.activeDateParticipant abbr {
     color: #ffffff !important;
    background: #56C02B;
    padding: 20px;
    }`}

        {`.react-calendar__tile--now {
      flex: 0 0 13.2857% !important;
      border-radius: 176px !important;
    }`}

        {`.react-calendar__tile {
      border-radius: 12px;
    }`}
        {`.react-calendar__tile:disabled {
        background-color: unset !important}`}

        {`.react-calendar {
      width: 100%;
      max-width: 100%;
      background: white;
      border: 1px solid #a0a096;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 2.125em;
      padding: 10px;
    }`}
        {`.react-calendar button {
      height: 42px;
      max-width: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 6px 17px;
      border-radius:48% !important;
    }`}
      </style>
      <Calendar
        tileClassName={({ date, view }) => {
          const isActive = isDateActive(date);
          const hasParticipant = filteredData.some(item => {
            const itemDate = new Date(item.startDate);
            return (
              itemDate.getFullYear() === date.getFullYear() &&
              itemDate.getMonth() === date.getMonth() &&
              itemDate.getDate() === date.getDate() &&
              item.participant
            );
          });

          if (view === 'month') {
            const isToday = date.toDateString() === new Date().toDateString();

            // If it's the current date and has a participant, use activeDateParticipant styles
            if (isActive && hasParticipant && isToday) {
              return 'activeDateParticipant';
            }

            // If it's the current date, but doesn't have a participant, use the default now style
            if (isToday) {
              return 'react-calendar__tile--now';
            }

            // Apply activeDateParticipant if it's not the current date but has a participant
            if (isActive && hasParticipant) {
              return 'activeDateParticipant';
            }

            // Apply activeDate for other active dates
            if (isActive) {
              return 'activeDate';
            }
          }

          return undefined;
        }}
        tileDisabled={({ date, view }) => {
          if (view === 'month') {
            return !isDateActive(date);
          }
          return false;
        }}
        onClickDay={handleDateClick}
      />
    </Dialog>
  );
};

export default RecurringRangeModal;
